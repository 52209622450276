import Domready from 'domready';

import App from './components/app.js';

Domready(
  () => {
    
    const APP = new App();

    APP.init();

  } 
); 