import Http from './parts/http';


export default class Wishlist extends Http
{

  constructor()
  {

    super();

    //http パラメータ
    this.parms = {
      type: 'GET',
      url: 'wp-json/custom/v1/wishlist_store',
      data: {
        id: 0,
        status: 'false'
      }
    };

    this.$target;

    this.$btn = $('.js-add_wishlist');

    this.$wishlistCnt = $('#js-wishlist_cnt');

    this.count = this.$wishlistCnt.data('count');

    this.max = this.$btn.length;

    this.status;

    this.event();

  }

  event()
  {

    this.$btn.on(
      'click',
      (e) => {

        if(!this.eventFg) return false;

        this.$target = $(e.currentTarget);

        this.parms.data.id = this.$target.data('id');

        this.parms.data.status = this.$target.attr('data-status');

        this.get();

        this.parms.data.status = this.$target.attr('data-status','loading');

        return false;
   
      }
    );



  }

  emit(data)
  {

    this.eventFg = true;

    this.changeStatus(data);

  }


  changeStatus(data)
  {

    for(let i = 0;i < this.max; i++)
    {

      let item_id = this.$btn.eq(i).data('id');

      if(item_id == data.id)
      {

        this.$btn.eq(i).attr(
          'data-status',
          data.status
        );

      }
      else
      {

        continue;

      }

    };

    if(data.status == 'true')
    {

      this.count++;

    }
    else
    {

      this.count--;

    }

    this.$wishlistCnt.attr('data-count',this.count).html(this.count);



  }



  
}
