export default class Http
{

  constructor()
  {

    this.base_url = homeurl;

    this.parms = {
      method: 'POST',
      url: '',
      dataType: 'json',
      headers:{
        'pragma': 'no-cache',
        'Cache-Control': 'no-cache'
      },
      data: {}
    };

    this.eventFg = true;

  }

  async get()
  {

    this.eventFg = false;

    await $.when(
      $.ajax(
        {
          type: this.parms.method,
          url: this.base_url + this.parms.url,
          data: this.parms.data
        })

    ).done(
      (data) => {

        this.emit(data);

      }
    ).fail(
      (errs) => {

        console.log(errs);

      }
    )

  }


}
