import Http from './parts/http';

import { NUMERIC } from './parts/valid';

/**
 * 絞り込み検索
 */
export default class Search extends Http
{
  
  constructor()
  {

    super();
    //http パラメータ
    this.parms = {
      type: 'GET',
      url: 'wp-json/custom/v1/ec_categorise',
      data: {
        id: 0
      }
    };
    //親category
    this.$categoryParent = $('#js-search_category_parent');
    //小カテゴリー
    this.$categoryChild = $('#js-search_category_child');
    //error tag
    this.$priceError = $('#js-price_error');
    //価格
    this.$price = $('.js-search_price');

    this.$submit = $('#js-search_submit');
    
    this.price = {
      min: 0,
      max: ''
    };
    //error message
    this.errorMessages = {
      min : '最大価格より低い数値を入力してください',
      max : '最低価格より高い数値を入力してください',
      numeric: '半角数字のみで入力してくだい'
    };

    this.submitStatus = false;

    this.init();

  }

  init ()
  {

    for(let i = 0,max = this.$price.length;i < max;i++)
    {

      let _price = this.$price.eq(i).val();

      if(_price != '')
      {
        let _type = this.$price.eq(i).data('type');
        
        this.price[_type] = Number(_price);
      
      };

      continue;


    };

    //event開始
    this.event();

  }

  event()
  {

    this.$categoryParent.on(
      'change',
      () => {

        let status = this.statusChild();

        if(status)
        {
          this.initialOptions();
        }
        else
        {

          this.get();

        };

      }
    );
    /**
     * 価格バリデーション
     */
    this.$price.on(
      'keyup blur',
      (e) => {

        let $this = $(e.target);

        let _price = $this.val();

        let _type = $this.data('type');

        let _message = '';
        //値があれば
        if(_price != '')
        {
          //バリデーション
          if(NUMERIC(_price))
          {
            //変数上書き
            this.price[_type] = Number(_price);
            //minの場合
            if(_type == 'min')
            {
              //最大値と同じか高い場合
              if(this.price.min >= this.price.max && this.price.max != '')
              {

                _message = this.errorMessages[_type];

                this.submitStatus = false;

              }
              else
              {

                this.submitStatus = true;

              };

            }
            else
            {
              //max

              //正の場合
              if(this.price.min < this.price.max)
              {

                this.submitStatus = true;

              }
              else
              {
                //最大値がminより低い場合
                _message = this.errorMessages[_type];

                this.submitStatus = false;

              };

            };

          }
          else
          {
            //数字列以外が入った場合

            this.submitStatus = false;

            _message = this.errorMessages.numeric;

          };

        }
        else
        {
          //からの場合minは0 maxは''
          this.price[_type] = (_type == 'min')?0:'';

          if(this.price.min == 0 && this.price.max == '')
          {

            this.submitStatus = true;

          };

        };

        //errorメッセージ更新
        this.$priceError.html(_message);

        if(this.submitStatus)
        {

          this.$submit.prop('disabled',false);

          this.$priceError.css('display','none');

        }
        else
        {

          this.$submit.prop('disabled',true);

          this.$priceError.css('display','block');

        };

      
        }
      );

  }
  /**
   * 
   */
  statusChild()
  {

    let value = this.$categoryParent.val();

    let status = (value != '')?false:true;

    this.parms.data.id = value;

    this.$categoryChild.prop('disabled',status);

    return status;

  }

  /**
   * parentからのemit
   * @param {*} data 
   */
  emit(data)
  {

    if(data.length > 0)
    {

      this.insertOptions(data);

    }
    else
    {

      this.initialOptions();      

    };

  }

  /**
   * select option追加
   * @param {*} data 
   */
  insertOptions(data)
  {

    let dom = "<option value=\"\">すべて</option>";

    for(let i = 0,max = data.length; i < max;i++)
    {

      dom += "<option value=\""+data[i].term_id+"\">"+data[i].name+"</option>";

    };

    this.$categoryChild.html(dom);

  }

  /**
   * 初期化
   */
  initialOptions()
  {

    this.$categoryChild.html("<option value=\"\">-</option>");

    this.$categoryChild.prop('disabled',true);


  }

}