import Search from './search';
import Wishlist from './wishlist';
export default class App
{

  constructor()
  {
    this.$document = $(document);

    this.$cartCount = $('#js-cart_count');
    
    this.cartCount = this.$cartCount.data('count');

    this.cartCount = Number(this.cartCount);

  }

  init ()
  {


    this.search();

    this.wishlist();

    this.removeCartCount();

  }


  search ()
  {

    const search = new Search(this);


  }

  wishlist()
  {

    return new Wishlist();

  }

  removeCartCount()
  {

    this.$document.on(
      'click',
      '.js-cart_remove',
      (e) => {

        let $this = $(e.target);

        let _quantity = $this.data('quantity');
        
        _quantity = Number(_quantity);

        this.cartCount = this.cartCount - _quantity;

        this.$cartCount.html(this.cartCount).attr('data-count',this.cartCount);

      }
    );



  }

}